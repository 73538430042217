/* eslint-disable spaced-comment */
/// <reference types="react-scripts"/>
import { useEthers } from "@usedapp/core"
import { makeStyles, AppBar} from "@material-ui/core"
import {Outlet, NavLink} from "react-router-dom"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ech from "../echlogo.jpg";
import { IconUserCircle } from '@tabler/icons-react';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState, useEffect } from 'react';
import { Footer } from "./footer"; 

// const useStyles = makeStyles((theme) => ({
//     container: {
//         padding: theme.spacing(4),
//         display: "flex",
//         justifyContent: "flex-end",
//         gap: theme.spacing(1)
//     }
// }))

export const Header = () => {

    const { account, activateBrowserWallet, deactivate } = useEthers()

    const isConnected = account !== undefined

    // const [ethereumAccount, setEthereumAccount] = useState('');
  
    useEffect(() => {
      if (isConnected) {
        sendaddress()
      }
  }, [account]);


    // const nullfunction = (): void => {
    //     console.warn('Account is not confirmed. Delaying sendAddress.')
    // }

    const sendaddress = async () => {
        const adduser = await fetch('https://bukvulo.pythonanywhere.com/api/onconnect',{
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
            },
            body:JSON.stringify({user_address : account})
        })   

    }

    return (
        <>
        <Navbar key="lg" expand="lg">
            <Container>
                <Navbar.Brand><NavLink to="/"><img
                    src= {ech}
                    width="250"
                    height="70"
                    className="d-inline-blocks align-top"
                    alt="ECHELON"/>
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
                <Navbar.Offcanvas
                    id= "offcanvasNavbar-expand-lg"
                    aria-labelledby= "offcanvasNavbarLabel-expand-lg"
                    placement="end"
                    >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                        ECHELON
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3" style={{color:"blue"}}>
                                <NavDropdown.Item className="depoldwn" ><NavLink to="exploreasset" className='depoldwnlink'>Explore</NavLink></NavDropdown.Item>
                                <NavDropdown.Item className="depoldwn" ><NavLink to="latestassets" className='depoldwnlink'>Latest Assets</NavLink></NavDropdown.Item>
                        </Nav>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav>{isConnected ? (
                                <Button style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE' }} className='mainbuyyon' variant="outline-success contained" onClick={deactivate}>
                                    Disconnect
                                </Button>
                            ) : (
                                <Button style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE' }} className='mainbuyyon' variant="outline-success contained"
                                    onClick={() => {
                                        activateBrowserWallet()
                                    }}
                                >
                                    Connect Wallet
                                </Button>
                            )}</Nav>
                            <Nav>
                                {isConnected ? (
                                    <Dropdown>
                                        <Dropdown.Toggle className='mainbuyyon' style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE' }} variant="success" id="dropdown-basic">
                                            <IconUserCircle className='mainbpol' style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100' }} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item className="depoldwn"><NavLink to="profile">Profile</NavLink></Dropdown.Item>
                                            <NavDropdown.Divider />
                                            <Dropdown.Item className="depoldwn"><NavLink to="echwallet">Wallet</NavLink></Dropdown.Item>
                                            <NavDropdown.Divider />
                                            <Dropdown.Item className="depoldwn"><NavLink to="echcreateasset">List Asset</NavLink></Dropdown.Item>
                                            <NavDropdown.Divider />
                                            <Dropdown.Item className="depoldwn"><NavLink to="echcreatecollections">Create Collection</NavLink></Dropdown.Item>
                                            <NavDropdown.Divider />
                                            <Dropdown.Item className="depoldwn"><NavLink to="profilesettings">Profile settings</NavLink></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    <p></p>
                                )}
                                    
                            </Nav>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
        <div>
            <Outlet/>
        </div>
        <div><Footer/></div>
        </>
    )
}