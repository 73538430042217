import { Token } from "../Wallet"
import {useEthers, useTokenBalance, useEtherBalance, useContractFunction} from "@usedapp/core"
import {formatUnits} from "@ethersproject/units"
import Container from 'react-bootstrap/Container';
import {Button} from "@material-ui/core"
import { useState, useEffect } from 'react';
import { BigNumber, Contract, utils } from 'ethers';
import FundMe from '../../chain-info/contracts/FundMe.json';
import networkMapping from "../../chain-info/deployments/map.json";
import { constants } from 'ethers';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Stack} from '@mui/material';
import { Alert } from '@mui/material';
import eth from '../../eth.png'

export interface WalletBalanceProps {
    token:Token
}

export const WalletBalance =({token}: WalletBalanceProps) =>{
    const{image, address, name}= token
    const { account } = useEthers()
    const isConnected = account !== undefined
    const [excngrate, setexcngrate] =useState<any>()
    const { chainId } = useEthers()
    const { abi } = FundMe
    const fundmeaddress = chainId ? networkMapping[String(chainId)]["FundMe"][0] : constants.AddressZero
    const FundMeInterface = new utils.Interface(abi)
    const FundMeContract = new Contract(fundmeaddress, FundMeInterface)
    const {send, state} = useContractFunction(FundMeContract, 'withdrawassetbalance')
    const ethbalance = useEtherBalance(account)
    const formatethbalance: number =ethbalance ? parseFloat(formatUnits(ethbalance, 18)) : 0
    const [isSuccess, setIsSuccess] = useState(false);
    const [issuffecient, setInsuffecient] = useState(false);


    useEffect(() => {
        fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
          .then(response => response.json())
          .then(data => setexcngrate(data))
          .catch(error => console.error('Error fetching exchange rate:', error));
      }, []);
    const [userbal, setuserbal] = useState<any>()
    useEffect(() => {
        const fetchUserbal = async () => {
          try {
            const response = await fetch(`https://bukvulo.pythonanywhere.com/api/getbalance/${account}`);
            if (response.ok) {
              const data = await response.json();
              setuserbal(data);
            } else {
              console.error('Error fetching bal:', response.status);
            }
          } catch (error) {
            console.error('Error fetching bal:', error);
          }
        };

        if (isConnected) {
            fetchUserbal()
        }
      }, [account]);

    const pool =formatethbalance > 0.01 ? formatethbalance - 0.005 : 0

    const xi = utils.parseEther(pool.toString())

    const tujan = formatethbalance > pool

    const price = userbal ? userbal :'0'

    const rate =excngrate ? excngrate.USD : '0'

    const ethbalusebal = rate>0 ? price/rate : 0

    // const trestbal = formatethbalance < 0.005 ? 0 : formatethbalance

    const combinebal = ethbalance || formatethbalance ? ethbalusebal + formatethbalance :0

    const combfinal = combinebal.toLocaleString()

    const excngbal = combinebal * rate

    const tfinal = excngbal.toLocaleString()

    const withdrawbal = async () => {
        tujan ? send({ value: xi }) :  setInsuffecient(true);
    }

    useEffect(() => {
        if (state.status === 'Success') {
           setIsSuccess(true);
        }
      }, [state]);


    return(
        <div>
            <Container>
                <div>
                    <Row>
                        <Col>
                            <div>
                                {isSuccess && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="success">Withdrawal SUCCESSFUL!!</Alert>
                                </Stack></div>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                {issuffecient && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="warning">Insuffecient Gas!</Alert>
                                </Stack></div>}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div >
                    <Row>
                        <Col>
                            <div className="pooloouh center-grid" style={{display:'flex',marginLeft:'35px'}}>
                                <div style={{display:'flex'}}>
                                    <p style={{fontSize:'11px',fontWeight:'650'}}>{combfinal}</p>
                                    <img style={{height:'16px',width:'16px',}} src={eth} alt="ETH Token" />
                                </div>
                                <div className="iollo"></div>
                                <div>
                                    <p style={{fontSize:'11px',fontWeight:'650'}} >$ {tfinal}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Button className='mainbuyyon' onClick={withdrawbal} style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE',width:'100%',fontFamily:"Lucida Handwriting" }}>WITHDRAW BALANCE</Button>
                </div>
            </Container>
        </div>
    )

}

/* {<div >{formatethbalance}<img src={image}></img></div>} */