/* eslint-disable spaced-comment */
/// <reference types="react-scripts"/>
import { ChainId, useEthers } from "@usedapp/core"
import helperConfig from "../helper-config.json"
import networkMapping from "../chain-info/deployments/map.json"
import {constants} from "ethers"
import eth from "../eth.png"
import { YourWallet } from "./yourwallet/YourWallet"


export type Token={
    image: string
    address: string
    name: string
}

export const Wallet = () =>{

    const { chainId, error } = useEthers()

    const networkName = chainId ? helperConfig[chainId] : "dev"

    const SimplestorageAddress = chainId ? networkMapping[String(chainId)]["SimpleStorage"][0] : constants.AddressZero

    const supportedTokens: Array<Token>=[
        {

            image: eth,
            address: "0x447Fd5eC2D383091C22B8549cb231a3bAD6d3fAf",
            name: "eth Balance"
        }
    ]

    return (
        <YourWallet supportedTokens={supportedTokens}/>
    )

}