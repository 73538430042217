import { useState } from "react"
import {Token} from "../Wallet"
import { WalletBalance } from "./WalletBalance"
import {TabContext, TabList, TabPanel} from "@material-ui/lab"
import {Tab} from "@material-ui/core"
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


interface YourWalletProps{
    supportedTokens: Array<Token>
}

export const YourWallet = ( {supportedTokens} : YourWalletProps ) => {

    const [selectedTokenIndex, setselectedTokenIndex] = useState<number>(0)

    const handleChange =(event: React.ChangeEvent<{}>, newValue: string)=>{
        setselectedTokenIndex(parseInt(newValue))
    }

    return(
        <>
        <Container>
                <Row>
                    <Col>
                        <div><h1 className="text-center" style={{color:'#AC2455',fontSize:'25px',fontWeight:'650' }}>Your wallet</h1></div>
                    </Col>
                </Row>
                <>
                    <Row>
                        <Col>
                            <div className="center-grid" style={{minHeight:'500px'}}>
                                <TabContext value={selectedTokenIndex.toString()}>
                                    <TabList onChange={handleChange} aria-label="stake form tabs">
                                        {supportedTokens.map((token, index) => {
                                            return(
                                                <Tab label={token.name}
                                                    value={index.toString()}
                                                    key={index}/>
                                            )
                                        })}
                                    </TabList>
                                    {supportedTokens.map((token, index) => {
                                        return(
                                            <TabPanel value={index.toString()} key={index}>
                                                <div>
                                                    <WalletBalance token={supportedTokens[selectedTokenIndex]}/>
                                                </div>
                                            </TabPanel>
                                        )
                                    })}
                                </TabContext>
                            </div>
                        </Col>
                    </Row>
                </>
        </Container>
        </>
    )

}