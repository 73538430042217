import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useContractFunction, useEthers, useEtherBalance } from '@usedapp/core';
import { Contract, utils } from 'ethers';
import FundMe from '../chain-info/contracts/FundMe.json';
import networkMapping from "../chain-info/deployments/map.json";
import { constants } from 'ethers';
import {formatUnits} from "@ethersproject/units"
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Stack} from '@mui/material';
import { Alert } from '@mui/material';

const defaultFormData= {
    newowner : "",
    oldowner : "",
    addedvalue :"",
}

export const Assetpg = () =>{
    const {assetid} = useParams()
    const [assets, setasset] = useState<any>()
    const {account} = useEthers()
    const isConnected = account !== undefined
    const [excngrate, setexcngrate] =useState<any>()
    const [issuffecient, setInsuffecient] = useState(false);
    const [formData, setformData] =useState(defaultFormData)
    const [isSuccess, setIsSuccess] = useState(false);


      useEffect(() => {
        fetch(`https://bukvulo.pythonanywhere.com/api/specificasset/${assetid}`)
          .then(response => response.json())
          .then(data => setasset(data))
          .catch(error => console.error('Error fetching va categories:', error));
      }, []);

    const { chainId } = useEthers()
    const { abi } = FundMe
    useEffect(() => {
        fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
          .then(response => response.json())
          .then(data => setexcngrate(data))
          .catch(error => console.error('Error fetching exchange rate:', error));
      }, []);
    const fundmeaddress = chainId ? networkMapping[String(chainId)]["FundMe"][0] : constants.AddressZero
    const FundMeInterface = new utils.Interface(abi)
    const FundMeContract = new Contract(fundmeaddress, FundMeInterface)
    const {send, state} = useContractFunction(FundMeContract, 'buyasset')
    const ethbalance = useEtherBalance(account)
    const formatethbalance: number =ethbalance ? parseFloat(formatUnits(ethbalance, 18)) : 0
    const rate =excngrate ? excngrate.USD : '0'
    const price = assets ? assets.assetprice :'0'
    const pi = rate>'0' ? price/rate : '0' 
    const innik = formatethbalance.toString() >= pi.toString()
    const xi = utils.parseEther(pi.toString())
    const pricexn = xi
    const [userbal, setuserbal] = useState<any>()
    useEffect(() => {
        const fetchUserbal = async () => {
          try {
            const response = await fetch(`https://bukvulo.pythonanywhere.com/api/getbalance/${account}`);
            if (response.ok) {
              const data = await response.json();
              setuserbal(data);
            } else {
              console.error('Error fetching bal:', response.status);
            }
          } catch (error) {
            console.error('Error fetching bal:', error);
          }
        };

        if (isConnected) {
            fetchUserbal()
        }
      }, [account]);

      const buyxasset = async () => {
        if (userbal >= price){
            onSubmitpost()
        }
        else{
            innik ? send({ value: pricexn }) : setInsuffecient(true);
        }
    }


      async function onSubmitpost() {
        if (typeof account === 'undefined') return
        if (typeof assets.assetcurrentowner === 'undefined') return 
        if (typeof assets.assetprice === 'undefined') return '0'
        const formDataToSend = new FormData();
        formDataToSend.append('newowner', account);
        formDataToSend.append('oldowner', assets.assetcurrentowner);
        formDataToSend.append('addedvalue', assets.assetprice);
        try {
          const response = await fetch(`https://bukvulo.pythonanywhere.com/api/buyassetg/${assetid}`, {
            method: 'POST',
            body: formDataToSend,
          });
      
          if (response.ok) {
            setIsSuccess(true);
            setformData(defaultFormData);
            console.log('Data sent successfully');
          } else {
            console.error('Failed to send data');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

      useEffect(() => {
        if (state.status === 'Success') {
          onSubmitpost();
        }
      }, [state]);


      const firstFiveLetters = assets && assets.assetcurrentowner ? (<>{assets.assetcurrentowner.slice(0, 16)}.......</>) : '';

      const pricemain = assets && assets.assetprice ? assets.assetprice : '';
      
      const pricemainxx = pricemain.toLocaleString()


    return (
        <div>
            <Container>
                <div>
                    <div>
                      {isSuccess && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="success">Asset Purchase SUCCESSFUL.!!</Alert>
                      </Stack></div>}
                    </div>
                    <div>
                      {issuffecient && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="warning">Insuffecient Funds!</Alert>
                      </Stack></div>}
                    </div>
                    {assets ? (
                    <div>
                        <div><h1 className='asethedr'>{assets.assetname}</h1></div>
                        <div><h2 className='blingin'>Owned By -  {firstFiveLetters}</h2></div>
                        <div>
                            <Card style={{ width: '100%', marginBottom: '56px' }}>
                                <Card.Img variant="top" style={{ height: '500px', padding: '10px' }} src={`https://bukvulo.pythonanywhere.com/static/nfts/${assets.assetpic}`}   />
                                <Card.Body>
                                    <Card.Title>Asset Category - {assets.assetcategory}</Card.Title>
                                    <Card.Title>Asset Price - ${assets.assetprice.toLocaleString()}</Card.Title>
                                    <hr  style={{ marginBottom:'4px', marginTop:'4px'}}/>
                                    { account === assets.assetcurrentowner ? <p></p> :
                                    <Button className='mainbuyyon' style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE',width:'100%' }} variant="primary"onClick={buyxasset}>Buy Asset</Button>
                                    }
                                    <hr style={{ marginBottom:'4px', marginTop:'4px'}}  />
                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Description</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row>
                                                            <Col>Minted By  {assets.assetcollection}</Col>
                                                        </Row>
                                                        <Row>
                                                            {assets.assetname ? <div><h2>About {assets.assetname}:</h2>
                                                            <Col>
                                                                <Card.Text>
                                                                    {assets.assetdesc}
                                                                </Card.Text>
                                                            </Col></div> : <p></p>}
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Details</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row>
                                                            <Col>Asset-Chain</Col>
                                                            <Col>{assets.assetchain}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>Creator-Earnings</Col>
                                                            <Col>{assets.assetcreatorearnings}%</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>Token-Standard</Col>
                                                            <Col>{assets.assettknstandard}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>Token-Id</Col>
                                                            <Col>{assets.assettknid}</Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                ):(
                    <div className='center-grid' style={{minHeight: '700px', width:'100%'}}>loading....</div>
                )}</div>
                
            </Container>
        </div>
    )

}