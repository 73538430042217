import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { useContractFunction, useEthers, useEtherBalance } from '@usedapp/core';
import { Contract, utils } from 'ethers';
import FundMe from '../../chain-info/contracts/FundMe.json';
import networkMapping from "../../chain-info/deployments/map.json";
import { constants } from 'ethers';
import {formatUnits} from "@ethersproject/units"
import { useState } from 'react';

export const Withdraweth = () =>{


    const { chainId } = useEthers()
    const { abi } = FundMe
    const fundmeaddress = chainId ? networkMapping[String(chainId)]["FundMe"][0] : constants.AddressZero
    const FundMeInterface = new utils.Interface(abi)
    const FundMeContract = new Contract(fundmeaddress, FundMeInterface)
    const {send, state} = useContractFunction(FundMeContract, 'withdraw')
    const [inputval, setinputval] = useState<any>()
    
    const handleinputchange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setinputval(e.target.value);
      }

    const {account} = useEthers()

    const xi = inputval === undefined ? '0' : utils.parseEther(inputval.toString())

    const pricexn = xi

    const makewithdrawal = async () => { 
         send({ value: pricexn })
    }

    return (
        <div>
            <Container>
                {account === "0x5580C424594B7677192c4c0E81864fceB0333E32" ? (
                    <><Row>
                        <Col>
                            <div><Link to='veruser' >verify user</Link></div>
                            <div><Link to='addbal' >Add user Balance</Link></div>
                            <div><Link to='adminpace' >Back to admin</Link></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <input onChange={handleinputchange} type="text" />
                                <button onClick={makewithdrawal}>Withdraw-eth</button>
                            </div>
                            <div>{fundmeaddress}</div>
                        </Col>
                    </Row></>
                ) : (
                    <div><p className='text-center' style={{ fontSize:'500'}}>404 PROFILE ACCESS NOT ALLOWED</p></div>
                )}
            </Container>
        </div>
    )

}