import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';



export const Accountsupport = () =>{

    return (
        <div>
            <Container>
            <Row >
                <Col>
                    <Row >
                        <Col lg="12"><h1 className=' mb-2 mt-5 ppfike'>Account Support</h1></Col>
                        <div className='profilesetbody'>
                        <Col lg="12">
                            <Link to="terms">
                                <Card className='mb-3' >
                                    <Card.Body>
                                        <Card.Title className='ppfike'>Terms & Conditions</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg="12">
                            <Link to="customercare">
                                <Card className='mb-3'>
                                    <Card.Body>
                                        <Card.Title className='ppfike'>Contact Customer Support</Card.Title>
                                        <Card.Text className='genplace'>
                                        Our customer support service is active 24/7 for you
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
        </div>
    )

}