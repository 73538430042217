import Container from 'react-bootstrap/Container';

export const Terms = () =>{

    return (
        <div>
            <Container>
                <div className='termhlrd'>
                    <div className='termtxthldr'>
                        <h1>Terms and Conditions</h1>
                        <ul>
                            <li>
                            Volatility. The prices of Crypto Assets are very volatile. Fluctuations in the price of other digital assets could materially and adversely affect the Crypto Assets, which may also be subject to significant price volatility. It cannot be guaranteed that any purchasers of Crypto Assets will not lose value or money.
                            </li>
                        </ul>
                    </div>
                    <div className='termtxthldr'>
                        <ul>
                            <li>
                            Taxation. You are solely responsible for determining what, if any, taxes apply to your Crypto Assets transactions. Neither Echelon nor any other Echelon Virtual Space Party is responsible for determining the taxes that apply to Crypto Assets transactions.
                            </li>
                        </ul>
                        <p>Disclaimers.</p>
                        <p>except as expressly provided to the contrary in a writing by Echelon Virtual Space , the service, content therein, and crypto assets listed therein are provided on an “as is” and “as available” basis without warranties or conditions of any sort, either express or implied. </p>
                    </div>
                    <div className='termtxthldr'>
                        <ul>
                            <li>
                            Change of Ownership Over a Crypto Asset. Our Service does not store, send, or receive Crypto Assets. The transfer of ownership over Crypto Assets occurs within a supporting blockchain and not through our Service.
                            </li>
                        </ul>
                    </div>
                    <div className='termtxthldr'>
                        <ul>
                            <li>
                            Inherent risks while transacting Crypto Assets. There are risks associated with transacting with Blockchain based assets such as Crypto Assets, including but not limited to, the risk of hardware, and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet. You accept and acknowledge that Echelon will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Crypto Assets, however caused. The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain, and new regulations or policies may materially adversely affect the development of the Auction and/or Service and the utility of Crypto Assets.


notwithstanding anything to the contrary contained herein, in no event shall the maximum aggregate liability of Echelon arising out of or in any way related to these terms, the access to and use of the service, content, crypto assets, or any products or services purchased on the service exceed the greater of (a) $100 or (b) the amount received by Echelon from the sale of crypto assets that are the subject of the claim.
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    )

}