import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';

import { useEthers } from '@usedapp/core';
import { useState } from 'react';
import {Stack} from '@mui/material';
import { Alert } from '@mui/material';

const defaultFormData= {
    collection_name: "",
    collect_userid: "",
    collection_bio: ""
}

export const Createcol = () =>{
    const {account} = useEthers()
    const [formData, setformData] =useState(defaultFormData)
    const [file, setfile] =useState<File | undefined>()
    const [filecov, setfilecov] =useState<any>()
    const {collection_name,collect_userid,collection_bio}= formData
    const [isSuccess, setIsSuccess] = useState(false);

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setformData((prevState) => ({
            ...prevState,
            [e.target.name]:e.target.value,
        }) )
    }

    const onFileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement & {
            files:FileList
        }
        setfile(target.files[0])
    };

    const onFileChangecov = (e:React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement & {
            files:FileList
        }
        setfilecov(target.files[0])
    };


    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (typeof file === 'undefined') return
        if (typeof filecov === 'undefined') return
        if (typeof account === 'undefined') return
        const formDataToSend = new FormData();
        formDataToSend.append('collection_bio', formData.collection_bio);
        formDataToSend.append('collection_name', formData.collection_name);
        formDataToSend.append('collection_ppix', file);
        formDataToSend.append('collection_covpix', filecov);
        formDataToSend.append('collect_userid', account);
        try {
            const response = await fetch('https://bukvulo.pythonanywhere.com/api/createcollection', {
              method: 'POST',
              body: formDataToSend,
            });
      
            if (response.ok) {
                setIsSuccess(true);
              console.log('Data sent successfully');
              setformData(defaultFormData)
            } else {
              console.error('Failed to send data');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        
    }


    return (
        <div>
            <Container>
                <Form onSubmit={onSubmit} style={{padding:'50px'}}>
                    <div>
                      {isSuccess && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="success">Collection Creating Successful!</Alert>
                      </Stack></div>}
                    </div>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label style={{color:'#AC2455'}}>Add Collection profile picture</Form.Label>
                        <Form.Control className='genplace' type="file" name='collection_ppix' onChange={onFileChange} />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label style={{color:'#AC2455'}}>Add Collection cover picture</Form.Label>
                        <Form.Control className='genplace' type="file" name='collection_covpix' onChange={onFileChangecov} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Collection name</Form.Label>
                        <Form.Control className='genplace' type="text" placeholder="Enter Collection name" name='collection_name' onChange={onChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label style={{color:'#AC2455'}}>Bio</Form.Label>
                        <Form.Control className='genplace' as="textarea" rows={3} placeholder="Tell us about your collection !" name='collection_bio' onChange={onChange}/>
                    </Form.Group>
                    <Button className='mainbuyyon' style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE',width:'100%',fontWeight:'bolder' }} variant="primary" type="submit">
                        Create Asset Collection
                    </Button>
                </Form>
            </Container>
        </div>
    )

}