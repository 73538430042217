import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

export const Latestassets = () =>{

    const [latstaset, setlatstaset] =useState<Array<any>>([])

    useEffect(() => {
        fetch('https://bukvulo.pythonanywhere.com/api/getlatestassetpage')
          .then(response => response.json())
          .then(data => setlatstaset(data))
          .catch(error => console.error('Error fetching latest assets:', error));
      }, []);

    return (
        <div style={{minHeight:'750px'}}>
            <Container>
                <div><h1 className='mb-5 mt-5 ppfike'>LATEST ASSETS</h1></div>
                <hr style={{color:'#73437C'}} className='mb-5 mt-2'/>
                <div>
                    <Row>
                        <Col>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 center-grid'>
                                {latstaset.map(asset => (
                                        <Link to={`/profile/${asset.id}`}>
                                        <Col className='nftcard' key={asset.id} lg={4}>
                                            <Card style={{ width: '15rem' }} key={asset.id}>
                                            <Card.Img variant="top" className='nftimg' src={`https://bukvulo.pythonanywhere.com/static/nfts/${asset.nftpic}`} key={asset.id} />
                                            <Card.Body>
                                                <Card.Title className='asrtnme'>{asset.nftname}</Card.Title>
                                            </Card.Body>
                                            </Card>
                                        </Col>
                                        </Link>
                                        ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )

}