import React, { FormEvent, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react';
import {Stack} from '@mui/material';
import { Alert } from '@mui/material';




export const Customercare = () =>{

    const form = useRef<HTMLFormElement | any>(null);
    const [isSuccess, setIsSuccess] = useState(false);

    const sendEmail = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs.sendForm('service_7oe2ngd', 'template_oyswp9o', form.current, 'MSPIVVPtjYwQG7SBG')
        .then((result) => {
            console.log(result.text);
            setIsSuccess(true)
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div>
            <Container  className='hlderminin'>
                <div className='reqbdybckrnd'>
                    <div>
                        <h1 className='hdertxt'>Customer Care</h1>
                    </div>
                    <div>
                        <div>
                        {isSuccess && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="success">Sent!  A customercare Representative would be with you shortly</Alert>
                        </Stack></div>}
                        </div>
                    </div>
                    <div className='frmhldr' >
                        <form ref={form} onSubmit={sendEmail} className='formelement'>
                            <label className='lbeltxt'>Name</label>
                            <input type="text" name="user_name" style={{borderRadius:'10px'}} />
                            <label className='lbeltxt'>Email</label>
                            <input type="email" name="user_email" style={{borderRadius:'10px'}} />
                            <label className='lbeltxt'>Message</label>
                            <textarea name="message" style={{borderRadius:'10px'}} />
                            <input className='veribtn' type="submit" value="Send" />
                        </form>
                    </div>
                </div> 
            </Container>
        </div>
    )

}