import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import echbrand from '../echiki.jpg'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InstagramOutlined } from '@ant-design/icons';
import { TwitterCircleFilled } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { FormControl } from 'react-bootstrap';
import React, { useRef, FormEvent } from 'react';
import emailjs from '@emailjs/browser';
import { useState, useEffect } from 'react';



export const Footer = () => {


    const form = useRef<HTMLFormElement | any>(null);

    const sendEmail = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs.sendForm('service_7oe2ngd', 'template_oyswp9o', form.current, 'MSPIVVPtjYwQG7SBG')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return(
        <div >
            <Container className='fiyyet'>
                <div>
                    <Row>
                        <Col style={{padding:'15px'}}>
                            <div style={{display:'flex', marginBottom:'20px'}}>
                                <Row className='fullftr'>
                                    <Col>
                                        <div style={{padding:'14px'}}>
                                            <div><img src={echbrand} alt="" style={{width:'60px',height:'60px'}} /></div>
                                            <div><p className='iujyh'>ECHELON</p><hr style={{color:'white'}}/></div>
                                            <div>
                                                <p style={{color:'white',fontSize:'12px',fontWeight:'bold'}}>The world’s first and largest virtual space for Assets (Digital Collectibles & Physical Assets) Built on Ethereum Network. We aim to bridge the space in the real and virtual world, with the help of Smart Contracts . Buy, sell, and discover exclusive digital & physical items.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='center-grid '>
                                            <div style={{marginTop:'75px'}}>
                                                <p style={{color:'white',fontSize:'12px',fontWeight:'bold'}}>Get to own physical and smart contracts of: Land Properties, Onsale homes, Digital Art, Physical Art, Photography, Gaming Nft Collectible, PFP, Membership Nft Collectible, Branded Nft Collectible, Virtual Fashion, Virtual Lands, Physical Collectibles all in one place!</p>
                                            </div>
                                        </div>
                                        <div className='center-grid '>
                                            <div style={{marginTop:'175px'}}><Link to='terms' style={{color:'white',fontSize:'12px',fontWeight:'bold'}}>Terms & conditions</Link></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='right-grid '>
                                            <div>
                                                <p style={{color:'white',fontSize:'12px',fontWeight:'bold',marginBottom:'20px',marginTop:'10px'}}>Join our mailing list to stay in the loop with our newest feature releases, Asset drops, and tips and tricks for navigating Echelon.</p>
                                            </div>
                                            <div>
                                                <Form ref={form} onSubmit={sendEmail} style={{display:'flex'}} >
                                                    <Form.Group >
                                                        <Form.Control name='message'/>
                                                    </Form.Group>
                                                    <Button style={{backgroundColor:'white',border:'2px solid white',color:'#AC2455', marginLeft:'4px'}}>subscribe</Button>
                                                </Form>
                                            </div>
                                            <div  style={{display:'flex'}}>
                                                <div><a href='https://instagram.com/echelonvirtualspace?igshid=NGVhN2U2NjQ0Yg==' target="_blank"><InstagramOutlined   style={{color:'white'}}/></a></div>
                                                <div><a href='https://twitter.com/echelonspace?t=AYTGruz84uXN8OqmPll0sA&s=09' target="_blank"><TwitterCircleFilled   style={{color:'white', marginLeft:'10px'}}/></a></div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <hr style={{color:'white'}} />
                            <div><p className='text-center copifuter' >Copyright &copy; 2022 - 2023 ECHELON INC, NO.1 virtual space, gallery & marketplace  </p></div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )

}