import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';

export const Adminpage = () =>{

    const {account} = useEthers()

    return (
        <div>
            <Container>
                {account === "0x5580C424594B7677192c4c0E81864fceB0333E32" ? (
                    <><Row>
                        <Col>
                            <div><Link to='addbal' >Add user Balance</Link></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div><Link to='veruser' >Verify user</Link></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div><Link to='withdraw' >Make withdraw</Link></div>
                        </Col>
                    </Row></>
                ) : (
                    <div><p className='text-center' style={{ fontSize:'500'}}>404 PROFILE ACCESS NOT ALLOWED</p></div>
                )}
            </Container>
        </div>
    )

}