import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEthers } from '@usedapp/core';
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import {Stack} from '@mui/material';
import { Alert } from '@mui/material';

const defaultFormData= {
    user_fullname:"",
    user_email:"",
    username:"",
    user_bio:"",
    user_phn:""
}

export const Editprofile = () =>{
    const {account} = useEthers()
    const [formData, setformData] =useState(defaultFormData)
    const [file, setfile] =useState<File | undefined>()
    const [filenx, setfilenx] =useState<any>()
    const {username,user_email,user_fullname,user_phn,user_bio}= formData
    const [isSuccess, setIsSuccess] = useState(false);

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setformData((prevState) => ({
            ...prevState,
            [e.target.name]:e.target.value,
        }) )
    }
    const isConnected = account !== undefined

    // const [ethereumAccount, setEthereumAccount] = useState('');

    const onFileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement & {
            files:FileList
        }
        setfile(target.files[0])
    };
    const onFileChangenx = (e:React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement & {
            files:FileList
        }
        setfilenx(target.files[0])
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (typeof file === 'undefined') return
        if (typeof account === 'undefined') return
        const formDataToSend = new FormData();
        formDataToSend.append('user_fullname', formData.user_fullname);
        formDataToSend.append('user_email', formData.user_email);
        formDataToSend.append('username', formData.username);
        formDataToSend.append('user_pix', file);
        formDataToSend.append('user_covpix', filenx);
        formDataToSend.append('user_bio', formData.user_bio);
        formDataToSend.append('user_phn', formData.user_phn);
        formDataToSend.append('user_address', account);
        try {
            const response = await fetch('https://bukvulo.pythonanywhere.com/api/updateprofile', {
              method: 'POST',
              body: formDataToSend,
            });
      
            if (response.ok) {
                setIsSuccess(true);
              console.log('Data sent successfully');
            } else {
              console.error('Failed to send data');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        setformData(defaultFormData)
    }

    return (
        <div>
            <Container>
                <Form onSubmit={onSubmit} style={{padding:'50px'}}>
                    <div>
                      {isSuccess && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="success">PROFILE UPDATED !!</Alert>
                      </Stack></div>}
                    </div>
                    <Form.Group className="mb-3 text-center" controlId="floatingInputGrid">
                        <Form.Control className='text-center'  style={{color:'#AC2455'}} type='text' name='user_address' value={account} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3 text-center" controlId="floatingInputGrid">
                        <Form.Control className='text-center'  style={{color:'#AC2455'}} type='text' name='user_notice' value="NOTE: YOU UPDATE YOUR ENTIRE PROFILE EACH TIME YOU SAVE.." readOnly />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label style={{color:'#AC2455'}}>Add new profile picture</Form.Label>
                        <Form.Control className='genplace' type="file" name="user_pix"  onChange={onFileChange}/>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label style={{color:'#AC2455'}}>Add new cover picture</Form.Label>
                        <Form.Control className='genplace' type="file"  name="user_covpix"  onChange={onFileChangenx}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Username</Form.Label>
                        <Form.Control className='genplace' type="text" placeholder="Enter username"  name="username"  onChange={onChange}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{color:'#AC2455'}}>Email address</Form.Label>
                        <Form.Control className='genplace' type="email" placeholder="Enter email"  name="user_email"   onChange={onChange}/>
                        <Form.Text className="text-muted genplace">
                        We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Full Name</Form.Label>
                        <Form.Control className='genplace' type="text" placeholder="Full name"  name="user_fullname" onChange={onChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Phone Number</Form.Label>
                        <Form.Control className='genplace' type="text" placeholder="Phone Number"  name="user_phn"  onChange={onChange}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label style={{color:'#AC2455'}}>Bio</Form.Label>
                        <Form.Control className='genplace' as="textarea" rows={3} placeholder="Share a piece of yourself !"  name='user_bio'  onChange={onChange} />
                    </Form.Group>
                    <Button variant="primary" className='mainbuyyon' type="submit" style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '400',border:'4px solid #56ADCE',width:'100%',fontWeight:'bolder' }}>
                        Save
                    </Button>
                </Form>
            </Container>
        </div>
    )

}