import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


export const Profilesettings = () =>{

    return (
        <>
        <Container>
            <Row >
                <Col>
                    <Row >
                        <Col lg="12"><h1 className=' mb-2 mt-5 ppfike'>~PROFILE SETTINGS</h1></Col>
                        <div className='profilesetbody'>
                        <Col lg="12">
                            <Link to="editprofile">
                                <Card className='mb-3' >
                                    <Card.Body>
                                        <Card.Title className='ppfike'>Edit Profile</Card.Title>
                                        <Card.Text className='genplace'>
                                        Edit your user profile.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg="12">
                            <Link to="accountsupport">
                                <Card className='mb-3'>
                                    <Card.Body>
                                        <Card.Title className='ppfike'>Account Support</Card.Title>
                                        <Card.Text className='genplace'>
                                        Contact Customer support for any questions regarding platform rules
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg="12">
                            <Link to="requestverification">
                                <Card className='mb-3'>
                                    <Card.Body>
                                        <Card.Title className='ppfike'>Request verification</Card.Title>
                                        <Card.Text className='genplace'>
                                        Request Account verification
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
        </>
    )

}