import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import {Stack} from '@mui/material';
import { Alert } from '@mui/material';
import { useContractFunction, useEthers, useEtherBalance } from '@usedapp/core';
import { Contract, utils } from 'ethers';
import FundMe from '../chain-info/contracts/FundMe.json';
import networkMapping from "../chain-info/deployments/map.json";
import { constants } from 'ethers';
import {formatUnits} from "@ethersproject/units"



const defaultFormData= {
    nft_name : "",
    nft_price : "",
    nft_pix:"",
    nft_desc : "",
    creator_earn : ""
}


export const Createasset = () =>{
    const {account} = useEthers()
    const [formData, setformData] =useState(defaultFormData)
    const [file, setfile] =useState<File | undefined>()
    const {nft_name,nft_price,nft_desc,creator_earn}= formData
    const [selectedCat, setSelectedCate] = useState<any>();
    const [selectedcollection, setcollectionfinal] = useState<any>();

    const [collection, setcollection] = useState<Array<any>>([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [issuffecient, setInsuffecient] = useState(false);
    const [excngrate, setexcngrate] =useState<any>()
    const { chainId } = useEthers()
    const { abi } = FundMe
    useEffect(() => {
        fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
          .then(response => response.json())
          .then(data => setexcngrate(data))
          .catch(error => console.error('Error fetching va categories:', error));
      }, []);
    const fundmeaddress = chainId ? networkMapping[String(chainId)]["FundMe"][0] : constants.AddressZero
    const FundMeInterface = new utils.Interface(abi)
    const FundMeContract = new Contract(fundmeaddress, FundMeInterface)
    const {send, state} = useContractFunction(FundMeContract, 'mintasset')
    const ethbalance = useEtherBalance(account)
    const formatethbalance: number =ethbalance ? parseFloat(formatUnits(ethbalance, 18)) : 0
    const rate =excngrate ? excngrate.USD : '0'
    const price = 400
    const pi = rate>'0' ? price/rate : '0' 
    const innik = formatethbalance.toString() >= pi.toString()
    const xi = utils.parseEther(pi.toString())
    const pricexn = xi


    useEffect(() => {
        const fetchUserProfile = async () => {
          try {
            const response = await fetch(`https://bukvulo.pythonanywhere.com/api/getcollect/${account}`);
            if (response.ok) {
              const data = await response.json();
              setcollection(data.collectget);
            } else {
              console.error('Error fetching profile:', response.status);
            }
          } catch (error) {
            console.error('Error fetching profile:', error);
          }
        };

        if (isConnected) {
            fetchUserProfile()
        }
      }, [account]);

    const [userbal, setuserbal] = useState<any>()
    useEffect(() => {
        const fetchUserbal = async () => {
          try {
            const response = await fetch(`https://bukvulo.pythonanywhere.com/api/getbalance/${account}`);
            if (response.ok) {
              const data = await response.json();
              setuserbal(data);
            } else {
              console.error('Error fetching bal:', response.status);
            }
          } catch (error) {
            console.error('Error fetching bal:', error);
          }
        };

        if (isConnected) {
            fetchUserbal()
        }
      }, [account]);


    const [categ, setcateg] = useState<Array<any>>([]);

    useEffect(() => {
      fetch('https://bukvulo.pythonanywhere.com/api/category')
        .then(response => response.json())
        .then(data => setcateg(data.catego))
        .catch(error => console.error('Error fetching va categories:', error));
    }, []);

    const handlecollectionchange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        setcollectionfinal(e.target.value);
      };
    const handlecategorychange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCate(e.target.value);
      };

    

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setformData((prevState) => ({
            ...prevState,
            [e.target.name]:e.target.value,
        }) )
    }
    const isConnected = account !== undefined

    const onFileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement & {
            files:FileList
        }
        setfile(target.files[0])
    };

    const onSubmitmint =async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (userbal > price){
        onSubmitpost()
      }
      else{
        innik ? send({ value: pricexn }) : setInsuffecient(true) ;
      }
      }

    useEffect(() => {
      if (state.status === 'Success') {
        onSubmitpost();
      }
    }, [state]);

        async function onSubmitpost() {
        if (typeof file === 'undefined') return
        if (typeof account === 'undefined') return
        if (typeof selectedCat === 'undefined') return
        if (typeof selectedcollection === 'undefined') return
        const formDataToSend = new FormData();
        formDataToSend.append('nft_desc', formData.nft_desc);
        formDataToSend.append('nft_price', formData.nft_price);
        formDataToSend.append('nft_name', formData.nft_name);
        formDataToSend.append('creator_earn', formData.creator_earn);
        formDataToSend.append('nft_pix', file);
        formDataToSend.append('nft_curentowner', account);
        formDataToSend.append('nft_category', selectedCat);
        formDataToSend.append('nft_collectid', selectedcollection);
        formDataToSend.append('trait_tknid', '2000');
        formDataToSend.append('trait_tknstandrd', "ERC20");
        formDataToSend.append('trait_chain', 'ethereum');
        formDataToSend.append('mint_value', '200');
        try {
          const response = await fetch('https://bukvulo.pythonanywhere.com/api/createnft', {
            method: 'POST',
            body: formDataToSend,
          });
      
          if (response.ok) {
            setIsSuccess(true);
            setformData(defaultFormData);
            console.log('Data sent successfully');
          } else {
            console.error('Failed to send data');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

    return (
        <div>
            <Container>
                <Form onSubmit={onSubmitmint} style={{padding:'50px'}}>
                    <div>
                      {isSuccess && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="success">Asset Minting Successful!</Alert>
                      </Stack></div>}
                    </div>
                    <div>
                      {issuffecient && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="warning">Insuffecient Funds!</Alert>
                      </Stack></div>}
                    </div>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label style={{color:'#AC2455'}}>Add Asset Image</Form.Label>
                        <Form.Control className='genplace' type="file" name='nft_pix' onChange={onFileChange}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Asset name</Form.Label>
                        <Form.Control className='genplace' type="text" name='nft_name'  placeholder="Enter Asset name" onChange={onChange}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Asset price ($)</Form.Label>
                        <Form.Control className='genplace' type="text" name='nft_price' placeholder="Asset price ($)" onChange={onChange}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Collections  <span style={{color:'red'}}>(all assets must belong to a collection)*</span></Form.Label>
                        <div>
                            <select className='genplace' value={selectedcollection} onChange={handlecollectionchange}>
                            <option>Select Collection</option>
                            {collection.map(collect => (
                                <option key={collect.id} value={collect.id}>
                                {collect.name}
                                </option>
                            ))}
                            </select>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Categories<span style={{color:'red'}}>*</span></Form.Label>
                        <div>
                            <select className='genplace' value={selectedCat} onChange={handlecategorychange}>
                            <option>Select Assets Category</option>
                            {categ.map(cat => (
                                <option  key={cat.id} value={cat.id}>
                                {cat.name}
                                </option>
                            ))}
                            </select>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                        <Form.Label style={{color:'#AC2455'}}>Creators Earnings</Form.Label>
                        <Form.Control type="text" className='genplace' name='creator_earn' placeholder="Asset creator earnings" onChange={onChange}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label style={{color:'#AC2455'}}>Description</Form.Label>
                        <Form.Control as="textarea" name='nft_desc' className='genplace' rows={3}  placeholder="Tell us about your work." onChange={onChange}/>
                    </Form.Group>
                    <Button className='mainbuyyon' style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE',width:'100%',fontWeight:'bolder' }} variant="primary" type="submit">
                        Create Asset
                    </Button>
                </Form>
            </Container>
        </div>
    )

}