import { useState, useEffect } from 'react';
import { useEthers } from '@usedapp/core';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { UserOutlined } from '@ant-design/icons';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import veribadge from '../Verified-badge.png'

export const Profile = () =>{

    const {account} = useEthers()

    const [profile, setprofile] =useState<any>()
    const [usernft, setusernft] =useState<Array<any>>([])
    const [usercollection, setusercollection] =useState<Array<any>>([])
    const isConnected = account !== undefined

      useEffect(() => {
        const fetchUserProfile = async () => {
          try {
            const response = await fetch(`https://bukvulo.pythonanywhere.com/api/userprofile/${account}`);
            if (response.ok) {
              const data = await response.json();
              setprofile(data);
            } else {
              console.error('Error fetching profile:', response.status);
            }
          } catch (error) {
            console.error('Error fetching profile:', error);
          }
        };

        if (isConnected) {
            fetchUserProfile()
        }
      }, [account]);

      useEffect(() => {
        const fetchUsernft = async () => {
          try {
            const response = await fetch(`https://bukvulo.pythonanywhere.com/api/usernft/${account}`);
            if (response.ok) {
              const data = await response.json();
              setusernft(data);
            } else {
              console.error('Error fetching profile:', response.status);
            }
          } catch (error) {
            console.error('Error fetching profile:', error);
          }
        };

        if (isConnected) {
          fetchUsernft()
        }
      }, [account]);

      useEffect(() => {
        const fetchUsercollection = async () => {
          try {
            const response = await fetch(`https://bukvulo.pythonanywhere.com/api/usercollection/${account}`);
            if (response.ok) {
              const data = await response.json();
              setusercollection(data);
            } else {
              console.error('Error fetching profile:', response.status);
            }
          } catch (error) {
            console.error('Error fetching profile:', error);
          }
        };

        if (isConnected) {
            fetchUsercollection()
        }
      }, [account]);
    
      if (!profile) {
        return <Container>
          <Row><div className='mt-5 mb-5'><Avatar size={64} icon={<UserOutlined />} /></div></Row>
          <Row>
                <Tabs  defaultActiveKey="assetscollected" id="fill-tab-example" className="mb-3  "  fill >
                    <Tab eventKey="assetscollected" style={{color:'#AC2455', marginTop:'70px',minHeight: '500px'}} title="NFTs Collected" className='text-center ' >
                      No Assets collected
                    </Tab>
                    <Tab eventKey="collections" style={{color:'#AC2455', marginTop:'70px',minHeight: '500px'}} title="Collections" className='text-center '>
                      No user collections
                    </Tab>
                </Tabs>
          </Row>
        </Container>;
      }

    return (
        <div>
            <Container>
                <Row>
                    <div className='picbody'>
                      {profile.pfp && profile.covp ? (
                        <div className='picbody'>
                          <Col xs={12} md={12}>
                            <Image src={`https://bukvulo.pythonanywhere.com/static/users/usercovp/${profile.covp}`} className='covpic' fluid />
                          </Col>
                          <Col xs={6} md={4}>
                          <Link to='editprofile'><Image src={`https://bukvulo.pythonanywhere.com/static/users/userpp/${profile.pfp}`} className='profilpic mb-5' roundedCircle />{profile.veri === true ? (<>
                          <div>
                            <img src={veribadge} className='ohtpoik'/>
                          </div>
                          </>) : (<></>)}</Link>
                          </Col>
                        </div>
                      ) : (
                        <div>
                          <Link to='editprofile'><Avatar size={80} icon={<UserOutlined/>}  className='mt-3 mb-5'/>{profile.veri === true ? (<>
                          <div>
                            <img src={veribadge} className='veripoik'/>
                          </div>
                          </>) : (<></>)}</Link>
                        </div>
                      )}
                    </div>
                </Row>
                <Row className='initusedeet mb-2 mt-12'>
                    <Col className='hugygn'>{profile.username}{!profile ? (<></>) : (<> | </>)}{profile.fullname}</Col>
                </Row>
                <Row className='usebio mb-3'>
                  <Col>{profile.bio}</Col>
                </Row>
                <Row style={{minHeight: '500px'}}>
                <Tabs  defaultActiveKey="assetscollected" id="fill-tab-example" className="mb-3"  fill >
                    <Tab eventKey="assetscollected" title="Assets Collected" className='text-center mt-5 mb-5 collectbody '>
                      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 center-grid'>
                      {usernft.map(asset => (
                            <Link to={`/profile/${asset.id}`}>
                              <Col className='nftcard' key={asset.id} lg={4}>
                                <Card style={{ width: '15rem' }} key={asset.id}>
                                  <Card.Img variant="top" className='nftimg' src={`https://bukvulo.pythonanywhere.com/static/nfts/${asset.nftpic}`} key={asset.id} />
                                  <Card.Body>
                                    <Card.Title>{asset.nftname}</Card.Title>
                                  </Card.Body>
                                </Card>
                              </Col>
                              </Link>
                            ))}
                      </div>
                    </Tab>
                    <Tab eventKey="collections" title="Collections" className='mt-5 mb-5 collectbody'>
                    {usercollection.map(usecollect => (
                              <Col>
                                <div className='flex mb-3'>
                                  <Image className='usercollectimg' roundedCircle src={`https://bukvulo.pythonanywhere.com/static/collections/collectionpp/${usecollect.collectpic}`} />
                                  <div className='usercolname'>{usecollect.collectname}</div>
                                  <hr />
                                </div>
                              </Col>
                            ))}
                    </Tab>
                </Tabs>
                </Row>
            </Container>
        </div>
    )

}