import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Stack} from '@mui/material';
import { Alert } from '@mui/material';


const defaultFormData= {
    userdeets: ""
}

export const Verifyuser = () =>{

    const [formData, setformData] =useState(defaultFormData)
    const {account} = useEthers()
    const [alluser, setalluser] = useState<Array<any>>([]);
    const [userfinal, setuserfinal] = useState<any>();
    const [isSuccess, setIsSuccess] = useState(false);
    const {userdeets}= formData


    const handleuserchange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        setuserfinal(e.target.value);
      };


    useEffect(() => {
        fetch('https://bukvulo.pythonanywhere.com/api/getallusers')
          .then(response => response.json())
          .then(data => setalluser(data))
          .catch(error => console.error('Error fetching assets:', error));
      }, []);
    

      const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formDataToSend = new FormData();
        formDataToSend.append('userdeets',userfinal);
        try {
            const response = await fetch('https://bukvulo.pythonanywhere.com/api/verifyuser', {
              method: 'POST',
              body: formDataToSend,
            });
      
            if (response.ok) {
                setIsSuccess(true)
              console.log('Data sent successfully');
            } else {
              console.error('Failed to send data');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        
    }

    return (
        <div>
            <Container>
                {account === "0x5580C424594B7677192c4c0E81864fceB0333E32" ? (
                    <><Row>
                    <Col>
                        <div><Link to='addbal' >Add user Balance</Link></div>
                        <div><Link to='withdraw' >Make withdraw</Link></div>
                        <div><Link to='adminpace' >Back to admin</Link></div>
                    </Col>
                    </Row>
                    <div>
                        <div>
                        {isSuccess && <div className="success-message"><Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="success">Verification Successful!</Alert>
                        </Stack></div>}
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <div>
                                <Form onSubmit={onSubmit} style={{padding:'50px'}}>
                                    <Form.Group className="mb-3" controlId="floatingInputGrid">
                                        <Form.Label style={{color:'#AC2455'}}>Verify users</Form.Label>
                                        <div>
                                            <select className='genplace' value={userfinal} onChange={handleuserchange}>
                                            <option>Select user</option>
                                                {alluser.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                    {user.id}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Form.Group>
                                    <Button className='mainbuyyon' style={{ backgroundColor: 'white', color:'#56ADCE', fontSize: '100',border:'4px solid #56ADCE',width:'100%',fontWeight:'bolder' }} variant="primary" type="submit">
                                        verify user
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row></>
                ) : (
                    <div><p className='text-center' style={{ fontSize:'500'}}>404 PROFILE ACCESS NOT ALLOWED</p></div>
                )}
            </Container>
        </div>
    )

}