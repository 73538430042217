import React from 'react';
import { DAppProvider, ChainId, Sepolia, Mainnet } from '@usedapp/core'
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { Header } from './components/header'
import { Main } from './components/Main';
import { Profilesettings } from './components/Profilesettings';
import { Latestassets } from './components/Latestassets';
import { Profile } from './components/Profile';
import { Wallet } from './components/Wallet';
import { Createasset } from './components/createasset';
import { Createcol } from './components/createcol';
import { Customercare } from './components/profilesettings/support';
import { Terms } from './components/profilesettings/support';
import { Accountsupport } from './components/profilesettings/accountsupport';
import { Editprofile } from './components/profilesettings/editprofile';
import { Requestveri } from './components/profilesettings/requestverification';
import { Assetpg } from './components/Asset';
import { Adminpage } from './components/adminpage';
import { Addbalance } from './components/admin';
import { Exploreassets } from './components/Exploreassets';
import { Withdraweth } from './components/admin';
import { Verifyuser } from './components/admin';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Header/>}>
      <Route index element= {<Main/>}/>
      <Route path='latestassets' element={<Latestassets/>}/>
      <Route path='profile' element={<Profile/>}/>
      <Route path='echwallet' element={<Wallet/>}/>
      <Route path='echcreateasset' element={<Createasset/>}/>
      <Route path='exploreasset' element={<Exploreassets/>}/>
      <Route path='echcreatecollections' element={<Createcol/>}/>
      <Route path='profilesettings' element={<Profilesettings/>}/>
      <Route path='profilesettings/accountsupport/customercare' element={<Customercare/>}/>
      <Route path='terms' element={<Terms/>}/>
      <Route path='profilesettings/accountsupport/terms' element={<Terms/>}/>
      <Route path='profilesettings/accountsupport' element={<Accountsupport/>}/>
      <Route path='profilesettings/editprofile' element={<Editprofile/>}/>
      <Route path='profile/editprofile' element={<Editprofile/>}/>
      <Route path='profilesettings/requestverification' element={<Requestveri/>}/>
      <Route path='profile/:assetid' element={<Assetpg/>} />
      <Route path='/:assetid' element={<Assetpg/>} />
      <Route path='profile/collections/:assetid' element={<Assetpg/>} />
      <Route path='latestassets/collections/:assetid' element={<Assetpg/>} />
      <Route path='auctionyourart/:assetid' element={<Assetpg/>} />
      <Route path='adminpace' element={<Adminpage/>} />
      <Route path='adminpace/withdraw' element={<Withdraweth/>} />
      <Route path='adminpace/withdraw/adminpace' element={<Adminpage/>} />
      <Route path='adminpace/addbal/adminpace' element={<Adminpage/>} />
      <Route path='adminpace/addbal' element={<Addbalance/>} />
      <Route path='adminpace/veruser' element={<Verifyuser/>} />
      <Route path='adminpace/veruser/adminpace' element={<Adminpage/>} />
    </Route>
  )
)



function App() {
  return (
    <DAppProvider config={{
      readOnlyChainId: Sepolia.chainId | Mainnet.chainId,
      readOnlyUrls: {
        [Sepolia.chainId]: 'https://sepolia.infura.io/v3/2e769818f41947548ed67d21e696c74e',
        [Mainnet.chainId]: 'https://mainnet.infura.io/v3/2e769818f41947548ed67d21e696c74e'
      }
    }}>
      <div>
        <RouterProvider router={router}/>
      </div>
    </DAppProvider>
  ) 
}

export default App
