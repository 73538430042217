/* eslint-disable spaced-comment */
/// <reference types="react-scripts"/>
import { ChainId, useEthers } from "@usedapp/core"
import helperConfig from "../helper-config.json"
import networkMapping from "../chain-info/deployments/map.json"
import {constants} from "ethers"
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import thredcp from "../3dcp.png";
import artstation from "../artstationlogo.png";
import axie from "../axie.png";
import christies from '../christies.jpg'
import decentra from "../decentraland.png";
import esty from "../Etsylogo.png"
import propy from '../PropyLogo.png'
import zillow from '../ZillowLogo.png'
import metamask from '../metamaskk.png'




export const Main = () =>{

    const { chainId, error } = useEthers()

    const {account} = useEthers()

    const networkName = chainId ? helperConfig[chainId] : "dev"

    const isConnected = account !== undefined

    const SimplestorageAddress = chainId ? networkMapping[String(chainId)]["SimpleStorage"][0] : constants.AddressZero

    const [estasse, latestasse] =useState<Array<any>>([])

    const [explorseven, setexplorseven] =useState<Array<any>>([])

    const [fetrdseven, setfetrdseven] =useState<Array<any>>([])

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      useEffect(() => {
        fetch('https://bukvulo.pythonanywhere.com/api/getlatestassetfistseven')
          .then(response => response.json())
          .then(data => latestasse(data))
          .catch(error => console.error('Error fetching latest assets:', error));
      }, []);

      useEffect(() => {
        fetch('https://bukvulo.pythonanywhere.com/api/getexplorefistseven')
          .then(response => response.json())
          .then(data => setexplorseven(data))
          .catch(error => console.error('Error fetching latest assets:', error));
      }, []);

      useEffect(() => {
        fetch('https://bukvulo.pythonanywhere.com/api/featuredassetfistseven')
          .then(response => response.json())
          .then(data => setfetrdseven(data))
          .catch(error => console.error('Error fetching latest assets:', error));
      }, []);

    return (
        <div>
            <Container>
                <div className="homebody">
                <Row>
                    <div className="position-relative text-center maihbuj">
                        <Col className="mb-3 mt5"><h1 className="text-center teader display-1">ECHELON</h1></Col>
                        <Col className="mt-5 mb-3 "><Link to='exploreasset'><Button className="text-center okolpgbtn">EXPLORE ECHELON</Button></Link></Col>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <div><Link to='latestassets'><h1 className="maintxfnt">Latest Assets</h1></Link></div>
                        <div>
                            <Carousel responsive={responsive}>
                                {estasse.map(latstaset => (<div><Link to={`/profile/${latstaset.id}`}><img className="latstaset" src={`http://bukvulo.pythonanywhere.com/static/nfts/${latstaset.nftpic}`} key={latstaset.id}/></Link></div>))}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div><h1 className="maintxfnt">Explore Assets</h1></div>
                        <div>
                            <Carousel responsive={responsive}>
                                {explorseven.map(sevexplor => (<div><Link to={`/profile/${sevexplor.id}`}><img className="latstaset" src={`http://bukvulo.pythonanywhere.com/static/nfts/${sevexplor.nftpic}`} key={sevexplor.id}/></Link></div>))}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div><h1 className="maintxfnt">Featured Assets</h1></div>
                        <div>
                            <Carousel responsive={responsive}>
                                {fetrdseven.map(svnfist => (<div><Link to={`/profile/${svnfist.id}`}><img className="latstaset" src={`http://bukvulo.pythonanywhere.com/static/nfts/${svnfist.nftpic}`} key={svnfist.id}/></Link></div>))}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div>
                        <div className="text-center"><h1 className="maintxfnt">Our Partners</h1></div>
                        <hr className="xconv"/>
                        <Row className="hlderkxx">
                            <Col className="mibblxx">
                                <div className="midminxx">
                                    <img className="edcp"  alt="thredcp" src={thredcp}/>
                                    <img className="rtstion" alt="artstion"  src={artstation}/>
                                </div>
                                <div className="midminxx">
                                    <img className="chris" alt="christi" src={christies}/>
                                    <img className="axie" alt="axi" src={axie}/>
                                </div>
                                <div className="midminxx">
                                    <img className="decentra" alt="decen" src={decentra}/>
                                    <img  className="prop" alt="propy" src={propy}/>
                                </div>
                                <div className="midminxx">
                                    <img className="est" alt="esty" src={esty}/>
                                    <img  className="zil" alt="zilow" src={zillow}/>
                                </div>
                                <div className="">
                                    <img className="meta" alt="metamsk" src={metamask}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div></div>
                    </Col>
                </Row>
                </div>
            </Container>
        </div>
    )

}